
	import { defineComponent, type PropType } from "vue";	
	import { type LogicBlock } from "./LogicModel";
	import LocalizationManager from "@/localization/LocalizationManager";
	import type { LogicDesignerLT } from "@/localization/LanguageData";

	export default defineComponent({
		props: {
			block: {
				type: Object as PropType<LogicBlock>,
				required: true
			},
			blockParent: {
				type: Object as PropType<LogicBlock>,
				required: true
			}
		},
		data() {
			return {
				localization: LocalizationManager
			}
		},
		computed: {
			l(): LogicDesignerLT {
				return this.localization.languageData.logicDesignerLT;
			},
			conditionLeadIn() {
				const blockIndex = this.blockParent.blocks.indexOf(this.block);
				if (blockIndex > 0 && this.blockParent.blocks[blockIndex - 1].conditions.length > 0) {
					if (this.block.conditions.length === 0) {
						return this.l.else;
					} else {
						return this.l.elseIf;
					}
				} else if (this.block.conditions.length > 0) {
					return this.l.if;
				} else {
					return "";
				}
			}
		}
	});
