import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    definition: _ctx.definition,
    "min-width": "200px"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.dialogDataTyped.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.dialogDataTyped.message
      }, null, 8, _hoisted_1)
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.yes();})
      }, _toDisplayString(_ctx.shared.yes), 1),
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.no();})
      }, _toDisplayString(_ctx.shared.no), 1)
    ]),
    _: 1
  }, 8, ["definition"]))
}