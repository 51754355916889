import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "return" }
const _hoisted_2 = { class: "input-cell-label" }
const _hoisted_3 = { class: "input-cell" }
const _hoisted_4 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logic_designer_condition_lead_in = _resolveComponent("logic-designer-condition-lead-in")!
  const _component_logic_designer_condition_container = _resolveComponent("logic-designer-condition-container")!
  const _component_logic_designer_block = _resolveComponent("logic-designer-block", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block.blocks, (subBlock, sb) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "logic-block",
      key: sb
    }, [
      _createVNode(_component_logic_designer_condition_lead_in, {
        block: subBlock,
        blockParent: _ctx.block
      }, null, 8, ["block", "blockParent"]),
      _createVNode(_component_logic_designer_condition_container, {
        model: subBlock,
        "expression-items": _ctx.expressionItems
      }, null, 8, ["model", "expression-items"]),
      _createVNode(_component_logic_designer_block, {
        model: subBlock,
        "expression-items": _ctx.expressionItems
      }, null, 8, ["model", "expression-items"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.l.result), 1),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": ($event: any) => ((subBlock.return) = $event),
            placeholder: _ctx.l.returnExpression
          }, null, 8, _hoisted_4), [
            [_vModelText, subBlock.return]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          class: "button",
          onClick: ($event: any) => {_ctx.blockAdd(subBlock);}
        }, _toDisplayString(_ctx.l.addSubBlocks), 9, _hoisted_6),
        _createElementVNode("a", {
          class: "button",
          onClick: ($event: any) => {_ctx.blockRemove(subBlock);}
        }, _toDisplayString(_ctx.l.removeBlock), 9, _hoisted_7)
      ])
    ]))
  }), 128))
}