
	import { defineComponent, type PropType } from "vue";
	import LogicDesignerConditionContainer from "./LogicDesignerConditionContainer.vue";
	import { type LogicBlock, LogicBooleanOperator, type LogicExpressionItem } from "./LogicModel";
	import type { LogicDesignerLT } from "@/localization/LanguageData";
	import LocalizationManager from "@/localization/LocalizationManager";

	export default defineComponent({
		components: { LogicDesignerConditionContainer },
		props: {
			model: {
				type: Object as PropType<LogicBlock>,
				required: true
			},
			isRoot: {
				type: Boolean,
				required: false,
				default: false
			},
			expressionItems: {
				type: Array as PropType<LogicExpressionItem[]>,
				required: false
			}
		},
		data() {
			return {
				localization: LocalizationManager,
				block: this.model
			}
		},
		computed: {
			l(): LogicDesignerLT {
				return this.localization.languageData.logicDesignerLT;
			}
		},
		methods: {
			blockAdd(parent: LogicBlock) {
				parent.blocks.push({
					blocks: [],
					conditions: [],
					booleanOperator: LogicBooleanOperator.AND,
					return: undefined
				});
			},
			blockRemove(subBlock: LogicBlock) {
				const subBlockIndex = this.block.blocks.indexOf(subBlock);
				if (subBlockIndex > -1) {
					this.block.blocks.splice(subBlockIndex, 1);
				}
			}			
		}
	});
