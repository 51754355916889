
	import { defineComponent, type PropType } from "vue";
	import { type LanguageSetting } from "@/localization/LanguageSetting";
	import Settings, { SettingsContext } from "@/settings";
	import LocalizationManager, { LocalizationManagerContext } from "@/localization/LocalizationManager";

	interface LocalInputData {
		settings: SettingsContext;
		localization: LocalizationManagerContext;
		language: LanguageSetting;
		valueObject: { [key:string]: string | undefined };
		value: string | undefined;
	}

	const LocalInput = defineComponent({
		props: {
			modelValue: {
				type: String as PropType<string>
			}
		},
		emits: ["update:modelValue"],
		data(): LocalInputData {
			const settings = Settings
			const localization = LocalizationManager;
			const language = LocalizationManager.language;
			const valueObject = LocalizationManager.stringValueParse(this.modelValue);	

			return {
				settings,
				localization,
				language,
				valueObject,
				value: valueObject[language.key]
			}
		},	
		computed: {
			uiLanguage(): LanguageSetting {				
				return this.localization.language;
			}
		},
		watch: {
			modelValue(n: string) {
				this.valueObject = LocalizationManager.stringValueParse(n);
				this.value = this.valueObject[this.language.key];
			}, 
			uiLanguage(n: LanguageSetting) {
				this.language = n;
				this.value = this.valueObject[this.language.key];
			}
		},
		methods: {
			valueChanged() {
				this.valueObject[this.language.key] = this.value;
				this.$emit("update:modelValue", JSON.stringify(this.valueObject));
			}
		}
	});

	export default LocalInput;
