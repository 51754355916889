
	import { defineComponent } from "vue";
	import LocalizationManager from "@/localization/LocalizationManager";	
	import { type LanguageSetting } from "@/localization/LanguageSetting";	
	import Settings from "@/settings";
	import DropDown from "../drop-down/DropDown.vue";

	const LangSelector = defineComponent({
		components: {
			DropDown
		},
		emits: ["languageChanged"],
		data() {
			return {
				settings: Settings,
				localization: LocalizationManager
			}
		},	
		methods: {
			languageChange(language: LanguageSetting) {
                this.localization.languageChange(language);
				this.$emit("languageChanged", language);
            }
		}
	});

	export default LangSelector;
