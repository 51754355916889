import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "input-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_time_span_editor = _resolveComponent("time-span-editor")!
  const _component_input_pair = _resolveComponent("input-pair")!
  const _component_dialog_window = _resolveComponent("dialog-window")!

  return (_openBlock(), _createBlock(_component_dialog_window, {
    definition: _ctx.definition,
    "min-width": "200px"
  }, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.dialogDataTyped.title), 1)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.dialogDataTyped.message
      }, null, 8, _hoisted_1),
      _createVNode(_component_input_pair, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_time_span_editor, {
              modelValue: _ctx.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
              modelModifiers: { number: true }
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      })
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.ok();})
      }, _toDisplayString(_ctx.shared.ok), 1),
      _createElementVNode("a", {
        class: "button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.cancel();})
      }, _toDisplayString(_ctx.shared.cancel), 1)
    ]),
    _: 1
  }, 8, ["definition"]))
}