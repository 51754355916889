import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "condition" }
const _hoisted_2 = {
  key: 0,
  class: "input-cell boolean-operator"
}
const _hoisted_3 = { value: 0 }
const _hoisted_4 = { value: 10 }
const _hoisted_5 = {
  key: 2,
  class: "condition-expressions"
}
const _hoisted_6 = {
  key: 1,
  class: "input-cell h"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "input-cell h" }
const _hoisted_9 = {
  key: 0,
  value: 0
}
const _hoisted_10 = {
  key: 1,
  value: 10
}
const _hoisted_11 = {
  key: 2,
  value: 20
}
const _hoisted_12 = {
  key: 3,
  value: 30
}
const _hoisted_13 = {
  key: 4,
  value: 40
}
const _hoisted_14 = {
  key: 5,
  value: 50
}
const _hoisted_15 = {
  key: 6,
  value: 60
}
const _hoisted_16 = {
  key: 7,
  value: 70
}
const _hoisted_17 = {
  key: 8,
  value: 80
}
const _hoisted_18 = {
  key: 9,
  value: 90
}
const _hoisted_19 = {
  key: 3,
  class: "input-cell h"
}
const _hoisted_20 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logic_designer_condition_container = _resolveComponent("logic-designer-condition-container")!
  const _component_auto_complete = _resolveComponent("auto-complete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ordinal > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.container.booleanOperator) = $event))
          }, [
            _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.l.booleanOperator.and), 1),
            _createElementVNode("option", _hoisted_4, _toDisplayString(_ctx.l.booleanOperator.or), 1)
          ], 512), [
            [_vModelSelect, _ctx.container.booleanOperator]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.condition.conditions.length > 0)
      ? (_openBlock(), _createBlock(_component_logic_designer_condition_container, {
          key: 1,
          model: _ctx.condition,
          "expression-items": _ctx.expressionItems
        }, null, 8, ["model", "expression-items"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.expressionItems)
            ? (_openBlock(), _createBlock(_component_auto_complete, {
                key: 0,
                class: "h",
                modelValue: _ctx.condition.expressionA,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.condition.expressionA) = $event)),
                display: _ctx.condition.expressionA,
                "onUpdate:display": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.condition.expressionA) = $event)),
                query: _ctx.query,
                "value-expression": (i) => i.expression,
                "display-expression": (i) => i.expression,
                "html-expression": (i) => i.expression,
                "show-on-focus": true
              }, null, 8, ["modelValue", "display", "query", "value-expression", "display-expression", "html-expression"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.condition.expressionA) = $event)),
                  placeholder: _ctx.l.expressionA
                }, null, 8, _hoisted_7), [
                  [_vModelText, _ctx.condition.expressionA]
                ])
              ])),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.condition.relationalOperator) = $event))
            }, [
              (_ctx.allowCompare)
                ? (_openBlock(), _createElementBlock("option", _hoisted_9, _toDisplayString(_ctx.l.relationalOperators.isLessThan), 1))
                : _createCommentVNode("", true),
              (_ctx.allowCompare)
                ? (_openBlock(), _createElementBlock("option", _hoisted_10, _toDisplayString(_ctx.l.relationalOperators.isGreaterThan), 1))
                : _createCommentVNode("", true),
              (_ctx.allowCompare)
                ? (_openBlock(), _createElementBlock("option", _hoisted_11, _toDisplayString(_ctx.l.relationalOperators.isLessThanOrEqualTo), 1))
                : _createCommentVNode("", true),
              (_ctx.allowCompare)
                ? (_openBlock(), _createElementBlock("option", _hoisted_12, _toDisplayString(_ctx.l.relationalOperators.isGreaterThanOrEqualTo), 1))
                : _createCommentVNode("", true),
              (_ctx.allowEqual)
                ? (_openBlock(), _createElementBlock("option", _hoisted_13, _toDisplayString(_ctx.l.relationalOperators.isEqualTo), 1))
                : _createCommentVNode("", true),
              (_ctx.allowEqual)
                ? (_openBlock(), _createElementBlock("option", _hoisted_14, _toDisplayString(_ctx.l.relationalOperators.isNotEqualTo), 1))
                : _createCommentVNode("", true),
              (_ctx.allowContains)
                ? (_openBlock(), _createElementBlock("option", _hoisted_15, _toDisplayString(_ctx.l.relationalOperators.contains), 1))
                : _createCommentVNode("", true),
              (_ctx.allowContains)
                ? (_openBlock(), _createElementBlock("option", _hoisted_16, _toDisplayString(_ctx.l.relationalOperators.doesNotContain), 1))
                : _createCommentVNode("", true),
              (_ctx.allowArrayContains)
                ? (_openBlock(), _createElementBlock("option", _hoisted_17, _toDisplayString(_ctx.l.relationalOperators.anyItemContains), 1))
                : _createCommentVNode("", true),
              (_ctx.allowArrayContains)
                ? (_openBlock(), _createElementBlock("option", _hoisted_18, _toDisplayString(_ctx.l.relationalOperators.noItemContains), 1))
                : _createCommentVNode("", true)
            ], 512), [
              [
                _vModelSelect,
                _ctx.condition.relationalOperator,
                void 0,
                { number: true }
              ]
            ])
          ]),
          (_ctx.expressionItem && _ctx.expressionItem.valuesQuery)
            ? (_openBlock(), _createBlock(_component_auto_complete, {
                key: 2,
                class: "h",
                modelValue: _ctx.condition.expressionB,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.condition.expressionB) = $event)),
                display: _ctx.condition.expressionB,
                "onUpdate:display": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.condition.expressionB) = $event)),
                query: _ctx.valuesQuery,
                "value-expression": (i) => i,
                "display-expression": (i) => i,
                "html-expression": (i) => i,
                "show-on-focus": true
              }, null, 8, ["modelValue", "display", "query", "value-expression", "display-expression", "html-expression"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.condition.expressionB) = $event)),
                  placeholder: _ctx.l.expressionA
                }, null, 8, _hoisted_20), [
                  [_vModelText, _ctx.condition.expressionB]
                ])
              ])),
          _createElementVNode("a", {
            class: "button",
            onClick: _cache[8] || (_cache[8] = ($event: any) => {_ctx.conditionAddSub(_ctx.condition);})
          }, _toDisplayString(_ctx.l.addSubConditions), 1),
          _createElementVNode("a", {
            class: "button",
            onClick: _cache[9] || (_cache[9] = ($event: any) => {_ctx.conditionRemove(_ctx.condition);})
          }, _toDisplayString(_ctx.l.removeCondition), 1)
        ]))
  ]))
}